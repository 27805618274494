<ng-container [formGroup]="form">
  <div class="row">
    <div class="col-12 col-sm-6 col-md-5">
      <div class="ui-float-label">
        <input pInputText libSharedUiUppercase type="text" class="form-control" formControlName="street_line1" />
        <label>Address Line 1</label>
      </div>
    </div>
    @if (!shortView()) {
      <div class="col-12 col-sm-6 col-md-5">
        <div class="ui-float-label">
          <input pInputText libSharedUiUppercase type="text" class="form-control" formControlName="street_line2" />
          <label>Address Line 2</label>
        </div>
      </div>
    }
    <div class="col-12 col-sm-6 col-md-2">
      <div class="ui-float-label">
        <input pInputText libSharedUiUppercase type="text" class="form-control" formControlName="city" />
        <label>City</label>
      </div>
    </div>
    <div class="col-12 col-sm-6 col-md-3">
      <div class="ui-float-label">
        <p-dropdown
          [options]="states"
          formControlName="state"
          [filter]="true"
          [virtualScroll]="false"
          [virtualScrollItemSize]="24.5"
          [overlayOptions]="{ autoZIndex: true, baseZIndex: 1100 }"
        ></p-dropdown>
        <label class="active">State</label>
      </div>
    </div>
    <div class="col-12 col-sm-6 col-md-2">
      <div class="ui-float-label">
        <input pInputText type="text" id="zip" class="form-control" formControlName="zip" />
        <label for="zip">Zip</label>
      </div>
    </div>
    @if (!shortView()) {
      <div class="col-12 col-sm-6 col-md-5">
        <div class="ui-float-label">
          <input pInputText libSharedUiUppercase type="text" class="form-control" formControlName="county" />
          <label>County</label>
        </div>
      </div>
      <div class="col-12 col-sm-6 col-md-2">
        <div class="ui-float-label">
          <input pInputText libSharedUiUppercase type="text" class="form-control" formControlName="country" />
          <label>Country</label>
        </div>
      </div>
    }
  </div>
</ng-container>
