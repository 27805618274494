import { ChangeDetectionStrategy, Component, DestroyRef, inject, OnInit, signal } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterLink } from '@angular/router';
import { filter, map, startWith } from 'rxjs';
import { MenuAccessService } from '@mca/auth/api';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

interface Breadcrumb {
  link: string;
  name: string;
  hasComponent: boolean;
}

interface BreadcrumbData {
  link: string;
  name: string;
}

@Component({
  selector: 'lib-shared-smart-ui-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [RouterLink],
})
export class BreadcrumbComponent implements OnInit {
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private menuAccessService = inject(MenuAccessService);
  private destroyRef = inject(DestroyRef);

  title = signal('');
  breadcrumbs = signal<Breadcrumb[]>([]);

  ngOnInit() {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.route),
        startWith(this.route),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(activeRoute => {
        let route: ActivatedRoute | null = activeRoute;
        let title = '';
        const breadcrumbs = [];
        do {
          const parentBreadcrumb = route.snapshot.data['parentBreadcrumb'];
          if (parentBreadcrumb) {
            breadcrumbs.push({
              link: parentBreadcrumb.link,
              name: parentBreadcrumb.name,
              hasComponent: true,
            } as Breadcrumb);
          }
          title = route.snapshot.data['title'];
          const breadcrumbData = route.snapshot.data['breadcrumb'] as BreadcrumbData | undefined;
          const name = breadcrumbData?.name || this.getRouteNameByUrl(route);
          if (breadcrumbData?.link) {
            breadcrumbs.push({
              link: this.menuAccessService.checkMenuRouteAccess(breadcrumbData.link) ? breadcrumbData.link : '',
              name: breadcrumbData.name,
              hasComponent: true,
            } as Breadcrumb);
          } else if (name) {
            breadcrumbs.push({
              link: this.testRouteUrl(route) && this.router.createUrlTree(['.'], { relativeTo: route }).toString(),
              name,
              hasComponent: !!route.snapshot.component,
            } as Breadcrumb);
          }
          route = route.firstChild;
        } while (route);
        this.breadcrumbs.set(breadcrumbs.filter(v => v.hasComponent));
        this.title.set(title);
      });
  }

  private getRouteNameByUrl(route: ActivatedRoute) {
    const routeUrl = this.testRouteUrl(route);
    const parentUrl = // check multiple parents as there can be consecutive default routes ('')
      this.testRouteUrl(route.parent) || this.testRouteUrl(route.parent?.parent) || this.testRouteUrl(route.parent?.parent?.parent);
    // prettify: capitalize and replace dashes
    return (routeUrl || parentUrl || '')
      .split('-')
      .map(v => v && v[0].toUpperCase() + v.slice(1))
      .join(' ');
  }

  private testRouteUrl(route?: ActivatedRoute | null) {
    if (!route) {
      return '';
    }
    const url = route.snapshot.url.join(' ');
    // skip empty urls and urls like .//
    return /^[./]*$/.test(url) ? '' : url;
  }
}
