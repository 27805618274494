<form class="row">
  <div class="col-6 ui-float-label">
    <p-multiSelect [options]="widgetOptions()" [formControl]="widgetsConrol"></p-multiSelect>
    <label class="active">Widgets</label>
  </div>
  <div class="col-6 ui-float-label" [formGroup]="form">
    <p-dropdown [options]="presetOptions()" formControlName="selectedPreset">
      <ng-template let-option pTemplate="item">
        <div class="d-flex w-100 align-items-center justify-content-between">
          <span>{{ option.label }}</span>
          <button class="btn btn-danger btn-sm w-auto ms-auto" type="button" (click)="$event.stopPropagation(); removePreset(option)">
            <i class="fa fa-trash"></i>
          </button>
        </div>
      </ng-template>
      <ng-template pTemplate="footer">
        <div class="row m-1 mb-2 align-items-center" (click)="$event.stopPropagation()">
          <div class="col-8">
            <div class="ui-float-label">
              <input pInputText formControlName="newPresetName" />
              <label class="active">Preset name</label>
            </div>
          </div>
          <div class="col-4">
            <button class="btn btn-secondary w-100" type="button" (click)="addPreset()">Add</button>
          </div>
        </div>
      </ng-template>
    </p-dropdown>
    <label class="active">Preset</label>
  </div>
</form>
