<div class="mb-3">
  <p-panel [header]="'Business Attributes'" [collapsed]="true" [toggleable]="true">
    @if (dispValues().length) {
      <div class="row">
        @for (record of dispValues(); track record; let i = $index) {
          @if (getSourceName(attributeMap()[record.id]?.source, i); as sourceName) {
            <p class="col-12 text-muted">Source: {{ sourceName }}</p>
          }
          <div [class]="valueStyleClass()">
            <div class="ui-float-label">
              @switch (attributeMap()[record.id]?.value_type) {
                @case ('str') {
                  <input pInputText type="text" [disabled]="disabled()" [(ngModel)]="record.value" (ngModelChange)="onValueChange()" />
                }
                @case ('number') {
                  <input
                    pInputText
                    type="text"
                    mask="N9*"
                    [disabled]="disabled()"
                    [(ngModel)]="record.value"
                    (ngModelChange)="onValueChange()"
                  />
                }
                @case ('currency') {
                  <input
                    pInputText
                    type="text"
                    mask="separator.2"
                    [disabled]="disabled()"
                    [(ngModel)]="record.value"
                    (ngModelChange)="onValueChange()"
                  />
                }
                @case ('date') {
                  <p-calendar
                    [(ngModel)]="record.value"
                    (ngModelChange)="onValueChange()"
                    dateFormat="mm/dd/yy"
                    appendTo="body"
                    [showButtonBar]="true"
                    [disabled]="disabled()"
                    class="wide-calendar-input"
                  ></p-calendar>
                }
                @case ('bool') {
                  <p-dropdown
                    [options]="booleanOptions"
                    [(ngModel)]="record.value"
                    [disabled]="disabled()"
                    (onChange)="onValueChange()"
                  ></p-dropdown>
                }
              }
              <label class="active">{{ attributeMap()[record.id]?.attribute_name }}</label>
            </div>
          </div>
        }
      </div>
    }
  </p-panel>
</div>
