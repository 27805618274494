import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef, OnInit, effect, inject, input, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormControl, NgControl, ReactiveFormsModule } from '@angular/forms';
import { LocalStorageService, RemoteStorageCachedService, StorageService } from '@mca/shared/domain';
import { mixinControlValueAccessor } from '@mca/shared/util';
import { SelectItem } from 'primeng/api';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { MultiSelectModule } from 'primeng/multiselect';
import {
  EMPTY,
  Observable,
  asyncScheduler,
  catchError,
  debounceTime,
  filter,
  observeOn,
  pairwise,
  startWith,
  subscribeOn,
  tap,
} from 'rxjs';

type WidgetId = number | string;

interface StorageValue {
  selectedPreset: string;
  presets: Record<string, WidgetId[]>;
  presetOptions: SelectItem<string>[];
}

const defaultOption = { label: 'Default', value: 'default' };

class WidgetSelectorBase {}
const WidgetSelectorMixedBase = mixinControlValueAccessor(WidgetSelectorBase);

@Component({
  selector: 'lib-shared-smart-ui-widget-selector',
  templateUrl: './widget-selector.component.html',
  styleUrls: ['./widget-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  providers: [
    LocalStorageService,
    {
      provide: StorageService,
      useClass: RemoteStorageCachedService,
    },
  ],
  imports: [MultiSelectModule, ReactiveFormsModule, DropdownModule, InputTextModule, AsyncPipe],
})
export class WidgetSelectorComponent extends WidgetSelectorMixedBase implements OnInit {
  private ngControl = inject(NgControl, { self: true, optional: true });
  private storage = inject(StorageService);
  private destroyRef = inject(DestroyRef);

  widgetOptions = input<SelectItem[]>([]);
  presetOptions = signal<SelectItem<string>[]>([defaultOption]);

  storageKey = input('');

  private storageValue = {
    selectedPreset: 'default',
    presets: { default: [] },
    presetOptions: [defaultOption],
  } as StorageValue;

  widgetsConrol = new FormControl([] as WidgetId[], { nonNullable: true });
  form = inject(FormBuilder).nonNullable.group({
    selectedPreset: 'default',
    newPresetName: '',
  });

  constructor() {
    super();
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
    effect(
      () => {
        if (!this.storageValue.presets.default.length) {
          const defaultPreset = this.widgetOptions().map(opt => opt.value);
          this.storageValue.presets.default = defaultPreset;
          this.widgetsConrol.setValue(defaultPreset, { emitEvent: false });
        }
      },
      // Added due to the issue with PrimeNG, may be removed later
      { allowSignalWrites: true },
    );
  }

  ngOnInit() {
    this.widgetsConrol = (this.ngControl?.control as FormControl) ?? this.widgetsConrol;
    this.widgetsConrol.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(value => this.ngControl?.viewToModelUpdate(value));
    this.watchStatus();
    this.syncWithStorage();
  }

  addPreset() {
    const presetName = this.form.value.newPresetName;
    if (!presetName) {
      return;
    }
    const presetId = this.formatPresetId(presetName);
    this.presetOptions.set([...this.presetOptions(), { label: presetName, value: presetId }]);
    // wait for dropdown to apply options
    setTimeout(() => {
      this.form.patchValue({ newPresetName: '', selectedPreset: presetId }, { emitEvent: false });
      this.updateStorage();
    });
  }

  removePreset(option: SelectItem) {
    this.form.patchValue({ selectedPreset: 'default' });
    const presetOptions = this.presetOptions().filter(o => o.value !== option.value);
    if (presetOptions.length === 0) {
      presetOptions.push(defaultOption);
      this.storageValue.presets.default = this.widgetOptions().map(opt => opt.value);
    }
    this.widgetsConrol.setValue(
      this.widgetsConrol.value.filter(presetId => presetId !== option.value),
      { emitEvent: false },
    );
    this.presetOptions.set(presetOptions);
    this.updateStorage();
  }

  private syncWithStorage() {
    this.widgetsConrol.valueChanges
      .pipe(
        subscribeOn(asyncScheduler),
        debounceTime(300),
        pairwise(),
        filter((prev, next) => JSON.stringify(prev) !== JSON.stringify(next)),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => this.updateStorage());
    this.form.controls.selectedPreset.valueChanges
      .pipe(
        tap(selectedPreset => this.widgetsConrol.setValue(this.storageValue.presets[selectedPreset], { emitEvent: false })),
        observeOn(asyncScheduler),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => this.updateStorage());
    // ensure that parent component is ready to receive the value
    Promise.resolve().then(() => this.readStorage());
  }

  private watchStatus() {
    this.widgetsConrol.statusChanges.pipe(startWith(this.widgetsConrol.status), takeUntilDestroyed(this.destroyRef)).subscribe(status => {
      if (status === 'DISABLED') {
        this.form.disable({ emitEvent: false });
      } else {
        this.form.enable({ emitEvent: false });
      }
    });
  }

  private formatPresetId(value: string) {
    return value.replace(/\s/g, '-').toLowerCase();
  }

  private updateStorage() {
    if (!this.storageKey) {
      return;
    }
    this.storageValue = {
      selectedPreset: this.form.value.selectedPreset ?? 'default',
      presets: this.presetOptions().reduce((acc, preset) => {
        const selectedWidgets =
          preset.value === this.form.value.selectedPreset ? this.widgetsConrol.value : this.storageValue.presets[preset.value];
        return Object.assign(acc, { [preset.value]: selectedWidgets });
      }, {}),
      presetOptions: this.presetOptions(),
    };
    (this.storage.set(this.storageKey(), this.storageValue) as Observable<StorageValue>).subscribe();
  }

  private readStorage() {
    if (!this.storageKey) {
      return;
    }
    (this.storage.get<StorageValue>(this.storageKey()) as Observable<StorageValue>)
      .pipe(
        filter(Boolean),
        catchError(() => EMPTY),
        tap(storageValue => this.presetOptions.set(storageValue.presetOptions)),
        observeOn(asyncScheduler),
      )
      .subscribe(storageValue => {
        this.storageValue = storageValue;
        this.form.patchValue({ selectedPreset: storageValue.selectedPreset }, { emitEvent: false });
        this.widgetsConrol.setValue(storageValue.presets[storageValue.selectedPreset]);
      });
  }
}
