import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AddressComponent } from './address/address.component';
import { DropdownModule } from 'primeng/dropdown';
import { BankStatementShitComponent } from './bank-statement-shit/bank-statement-shit.component';
import { FieldsetModule } from 'primeng/fieldset';
import { TableModule } from 'primeng/table';
import { MultiSelectModule } from 'primeng/multiselect';
import { ExpectedExposureComponent } from './expected-exposure/expected-exposure.component';
import { SharedUiModule } from '@mca/shared/ui';
import { OutstandingloanviewComponent } from './outstandingloanview/outstandingloanview.component';
import { InputTextModule } from 'primeng/inputtext';
import { NgxMaskDirective } from 'ngx-mask';
import { TooltipModule } from 'primeng/tooltip';
import { CalendarModule } from 'primeng/calendar';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DropdownModule,
    FieldsetModule,
    TableModule,
    MultiSelectModule,
    SharedUiModule,
    InputTextModule,
    TooltipModule,
    CalendarModule,
    RouterModule,
    NgxMaskDirective,
    AddressComponent,
    BankStatementShitComponent,
    ExpectedExposureComponent,
    OutstandingloanviewComponent,
    BreadcrumbComponent,
  ],
  exports: [
    AddressComponent,
    BankStatementShitComponent,
    ExpectedExposureComponent,
    SharedUiModule,
    OutstandingloanviewComponent,
    BreadcrumbComponent,
  ],
})
export class SharedFeatureSmartUiModule {}
