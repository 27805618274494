import { ChangeDetectionStrategy, Component, inject, input, model, output } from '@angular/core';
import { OutstandingLoan } from '@mca/shared/domain';
import { MessageService, SharedModule } from 'primeng/api';
import { SafeNumberPipe } from '@mca/shared/ui';
import { DecimalPipe, DatePipe } from '@angular/common';
import { CalendarModule } from 'primeng/calendar';
import { FormsModule } from '@angular/forms';
import { TooltipModule } from 'primeng/tooltip';
import { TableModule } from 'primeng/table';

@Component({
  selector: 'lib-shared-smart-ui-outstandingloanview',
  templateUrl: './outstandingloanview.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TableModule, SharedModule, TooltipModule, FormsModule, CalendarModule, DecimalPipe, DatePipe, SafeNumberPipe],
})
export class OutstandingloanviewComponent {
  private messageService = inject(MessageService);

  loans = model<OutstandingLoan[]>([]);
  disabled = input(false);
  totBal = input(0);
  totPay = input(0);
  removeEvt = output<number>();

  get allConsSelected() {
    return !this.loans().some(loan => !loan.consolidate);
  }

  removeRec(item: OutstandingLoan) {
    const idx = this.loans().indexOf(item);
    this.removeEvt.emit(idx);
  }

  onBuyoutChange(item: OutstandingLoan) {
    if (!this.validateBuyout(item)) {
      return;
    }
    if (item.buyout) {
      item.consolidate = true;
      this.loans.set([...this.loans()]);
    }
  }

  massToggleConsolidate(value: boolean) {
    for (const loan of this.loans()) {
      loan.consolidate = value;
    }
    this.loans.set([...this.loans()]);
  }

  private validateBuyout(item: OutstandingLoan) {
    if (!item.buyout || item.refmca) {
      return true;
    }
    this.messageService.add({
      severity: 'error',
      summary: 'Provide MCA# of the deal you’re planning to BUYOUT before clicking the checkbox',
    });
    setTimeout(() => {
      item.buyout = false;
      this.loans.set([...this.loans()]);
    });
    return false;
  }
}
