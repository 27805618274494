<p-table [value]="loans()">
  <ng-template pTemplate="header">
    <tr>
      <th>Name</th>
      <th>Fund Date</th>
      <th>Renewal</th>
      <th>Fund Amt</th>
      <th>Balance ASOF</th>
      <th style="width: 40px" pTooltip="Ignore Funding Date">IFD</th>
      <th>Calc Date</th>
      <th>Payout Date</th>
      <th>Amount</th>
      <th>Factor Rate</th>
      <th>Pmnt</th>
      <th>Weekly Pmnt</th>
      <th>Balance</th>
      <th>Rem Days</th>
      <th>Rem Weeks</th>
      <th style="width: 50px">Cons</th>
      <th pTooltip="Immediate buyout">Buyout</th>
      <th pTooltip="Internal buyout contract number">MCA#</th>
      <th style="width: 46px"></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rec>
    <tr>
      <td pEditableColumn>
        <p-cellEditor>
          <ng-template pTemplate="input">
            <input type="text" [(ngModel)]="rec.name" [disabled]="disabled()" />
          </ng-template>
          <ng-template pTemplate="output">
            {{ rec.name }}
          </ng-template>
        </p-cellEditor>
      </td>
      <td pEditableColumn>
        <p-cellEditor>
          <ng-template pTemplate="input">
            <p-calendar [(ngModel)]="rec.fundingDate" dateFormat="mm/dd/yy" appendTo="body" [showButtonBar]="true"></p-calendar>
          </ng-template>
          <ng-template pTemplate="output">
            {{ rec.fundingDate | date: 'MM/dd/yy' }}
          </ng-template>
        </p-cellEditor>
      </td>
      <td>
        <input type="checkbox" [(ngModel)]="rec.renewal" [disabled]="disabled()" />
      </td>
      <td pEditableColumn>
        <p-cellEditor>
          <ng-template pTemplate="input">
            <input type="number" [(ngModel)]="rec.fundingAmmount" />
          </ng-template>
          <ng-template pTemplate="output">
            {{ rec.fundingAmmount | safeNumber: '1.0-2' }}
          </ng-template>
        </p-cellEditor>
      </td>
      <td pEditableColumn>
        <p-cellEditor>
          <ng-template pTemplate="input">
            <p-calendar [(ngModel)]="rec.date" dateFormat="mm/dd/yy" appendTo="body" [showButtonBar]="true"></p-calendar>
          </ng-template>
          <ng-template pTemplate="output">
            {{ rec.date | date: 'MM/dd/yy' }}
          </ng-template>
        </p-cellEditor>
      </td>
      <td>
        <input type="checkbox" [(ngModel)]="rec.ignorefundingdate" [disabled]="disabled()" />
      </td>
      <td pEditableColumn>
        <p-cellEditor>
          <ng-template pTemplate="input">
            <p-calendar [(ngModel)]="rec.calcDate" dateFormat="mm/dd/yy" appendTo="body" [showButtonBar]="true"></p-calendar>
          </ng-template>
          <ng-template pTemplate="output">
            {{ rec.calcDate | date: 'MM/dd/yy' }}
          </ng-template>
        </p-cellEditor>
      </td>
      <td pEditableColumn>
        {{ rec.lasdtPayDate | date: 'MM/dd/yy' }}
      </td>
      <td pEditableColumn>
        <p-cellEditor>
          <ng-template pTemplate="input">
            <input type="number" [(ngModel)]="rec.ammount" />
          </ng-template>
          <ng-template pTemplate="output">
            {{ rec.ammount | safeNumber: '1.0-2' }}
          </ng-template>
        </p-cellEditor>
      </td>
      <td pEditableColumn>
        <p-cellEditor>
          <ng-template pTemplate="input">
            <input type="number" [(ngModel)]="rec.factorRate" [disabled]="disabled()" />
          </ng-template>
          <ng-template pTemplate="output">
            {{ rec.factorRate | safeNumber: '1.0-4' }}
          </ng-template>
        </p-cellEditor>
      </td>
      <td pEditableColumn>
        <p-cellEditor>
          <ng-template pTemplate="input">
            <input type="number" [(ngModel)]="rec.payment" [disabled]="disabled()" />
          </ng-template>
          <ng-template pTemplate="output">
            {{ rec.payment | safeNumber: '1.0-2' }}
          </ng-template>
        </p-cellEditor>
      </td>
      <td>
        <input type="checkbox" [(ngModel)]="rec.weekly_payment" [disabled]="disabled()" />
      </td>
      <td pEditableColumn>
        <p-cellEditor>
          <ng-template pTemplate="input">
            <input type="number" [(ngModel)]="rec.balanceRemains" [disabled]="disabled()" />
          </ng-template>
          <ng-template pTemplate="output">
            {{ rec.balanceRemains | safeNumber: '1.0-2' }}
          </ng-template>
        </p-cellEditor>
      </td>
      <td>
        {{ rec.daysLeft | safeNumber: '1.0-2' }}
      </td>
      <td>
        {{ rec.weeksLeft | safeNumber: '1.0-2' }}
      </td>
      <td>
        <input type="checkbox" [(ngModel)]="rec.consolidate" [disabled]="disabled()" />
      </td>
      <td>
        <input type="checkbox" [(ngModel)]="rec.buyout" [disabled]="disabled()" (ngModelChange)="onBuyoutChange(rec)" />
      </td>
      <td pEditableColumn>
        <p-cellEditor>
          <ng-template pTemplate="input">
            <input type="number" [ngModel]="rec.refmca || ''" (ngModelChange)="rec.refmca = $event || 0" [disabled]="disabled()" />
          </ng-template>
          <ng-template pTemplate="output">
            {{ rec.refmca || '' }}
          </ng-template>
        </p-cellEditor>
      </td>
      <td>
        <button type="button" class="btn btn-danger btn-sm" (click)="removeRec(rec)" [disabled]="disabled()" pTooltip="Remove record">
          <i class="fa fa-trash"></i>
        </button>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="footer">
    <tr>
      <td colspan="11">Totals</td>
      <td>${{ totPay() | number: '1.0-2' }}</td>
      <td>${{ totBal() | number: '1.0-2' }}</td>
      <td colspan="2"></td>
      <td><input type="checkbox" [ngModel]="allConsSelected" (ngModelChange)="massToggleConsolidate($event)" [disabled]="disabled()" /></td>
      <td colspan="3"></td>
    </tr>
  </ng-template>
</p-table>
