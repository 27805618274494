/* eslint-disable @typescript-eslint/naming-convention */
import { ChangeDetectionStrategy, Component, DestroyRef, OnInit, effect, inject, input, model, output, signal } from '@angular/core';
import { BankStatementRec, BankStatementRecDate, BankStatementShit, monthNames } from '@mca/shared/domain';
import { MessageService, SelectItem, SharedModule } from 'primeng/api';
import { DialogService } from '@mca/shared/feature-dialog';
import { MCARec, McaService } from '@mca/mca/api';
import { EMPTY, catchError, debounceTime, filter, finalize, first, forkJoin, tap } from 'rxjs';
import { NonNullableFormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskDirective } from 'ngx-mask';
import { NgStyle, NgClass, AsyncPipe, DecimalPipe, CurrencyPipe } from '@angular/common';
import { TooltipModule } from 'primeng/tooltip';
import { TableModule } from 'primeng/table';
import { SubmitBtnComponent } from '@mca/shared/ui';
import { MultiSelectModule } from 'primeng/multiselect';
import { InputTextModule } from 'primeng/inputtext';
import { FieldsetModule } from 'primeng/fieldset';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';

@Component({
  selector: 'lib-shared-smart-ui-bank-statement-shit',
  templateUrl: './bank-statement-shit.component.html',
  styleUrls: ['./bank-statement-shit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FieldsetModule,
    FormsModule,
    ReactiveFormsModule,
    InputTextModule,
    MultiSelectModule,
    SubmitBtnComponent,
    TableModule,
    SharedModule,
    TooltipModule,
    NgStyle,
    NgxMaskDirective,
    NgClass,
    AsyncPipe,
    DecimalPipe,
    CurrencyPipe,
  ],
})
export class BankStatementShitComponent implements OnInit {
  private dialogService = inject(DialogService);
  private messageService = inject(MessageService);
  private mcaService = inject(McaService);
  private fb = inject(NonNullableFormBuilder);
  private destroyRef = inject(DestroyRef);

  statement = model.required<BankStatementShit>();
  disabled = input(false);
  mca = input<MCARec>({} as MCARec);
  loading = signal(false);
  private loading$ = toObservable(this.loading);

  removeEmpty = output<void>();
  saved = output<void>();

  form = this.fb.group({
    name: '',
    year: 0,
    monthSelected: [[] as string[]],
  });

  monthOptions: SelectItem[] = monthNames.map(m => ({ label: m, value: m }));

  constructor() {
    effect(() => {
      this.form.patchValue({ name: this.statement().name, year: this.statement().year }, { emitEvent: false });
    });
    effect(() => {
      if (this.disabled()) {
        this.form.disable();
        return;
      }
      this.form.enable();
    });
  }

  ngOnInit() {
    this.form.patchValue({ monthSelected: this.statement().recs.map(r => r.month) });

    this.form.valueChanges.pipe(debounceTime(200), takeUntilDestroyed(this.destroyRef)).subscribe(formValue => {
      this.statement.set({ ...this.statement(), name: formValue.name ?? '', year: formValue.year ?? 0 });
    });

    this.form.controls.monthSelected.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => this.changeMonth());
    // sort records
    this.changeMonth();
    this.recalc();
  }

  confirmDelete() {
    this.dialogService
      .confirm({
        title: 'Delete Confirmation',
        targetName: 'bank',
        action: 'delete',
      })
      .pipe(filter(Boolean))
      .subscribe(() => {
        this.dialogService.closeModal();
        const statement = { ...this.statement() };
        statement.recs = [];
        this.statement.set(statement);
        this.removeEmpty.emit();
        this.save();
        this.loading$
          .pipe(
            filter(loading => !loading),
            first(),
          )
          .subscribe(() => this.saved.emit());
      });
  }

  changeMonth() {
    const monthSelected = this.form.controls.monthSelected.value;
    const statement = { ...this.statement() };
    statement.recs = this.monthOptions
      .filter(opt => monthSelected.includes(opt.value))
      .map(({ value }) => {
        const existingRecord = statement.recs.find(rec => rec.month === value);
        return existingRecord || new BankStatementRecDate(value);
      });
    this.statement.set(statement);
  }

  recalc() {
    const statement = { ...this.statement() };
    Object.assign(statement.total, {
      grossDepositsAmt: 0,
      depositsAmt: 0,
      avgDailyBankBalanceAmt: 0,
      monthlyWithdrawalsAmt: 0,
      endMonthBankBalanceAmt: 0,
      monthlyBankDeposits: 0,
      daysNegative: 0,
      NSFItems: 0,
      ODItems: 0,
    } as BankStatementRec);

    statement.recs.forEach(item => {
      if (!isNaN(item.data.grossDepositsAmt)) {
        statement.total.grossDepositsAmt += Number(item.data.grossDepositsAmt);
      }
      if (!isNaN(item.data.depositsAmt)) {
        statement.total.depositsAmt += item.data.grossDepositsAmt ? Number(item.data.depositsAmt) : 0;
      }
      if (!isNaN(item.data.avgDailyBankBalanceAmt)) {
        statement.total.avgDailyBankBalanceAmt += Number(item.data.avgDailyBankBalanceAmt);
      }
      if (!isNaN(item.data.monthlyWithdrawalsAmt)) {
        statement.total.monthlyWithdrawalsAmt += Number(item.data.monthlyWithdrawalsAmt);
      }
      if (!isNaN(item.data.endMonthBankBalanceAmt)) {
        statement.total.endMonthBankBalanceAmt += Number(item.data.endMonthBankBalanceAmt);
      }
      if (!isNaN(item.data.monthlyBankDeposits)) {
        statement.total.monthlyBankDeposits += Number(item.data.monthlyBankDeposits);
      }
      if (!isNaN(item.data.daysNegative)) {
        statement.total.daysNegative += Number(item.data.daysNegative);
      }
      if (!isNaN(item.data.NSFItems)) {
        statement.total.NSFItems += Number(item.data.NSFItems);
      }
      if (!isNaN(item.data.ODItems)) {
        statement.total.ODItems += Number(item.data.ODItems);
      }
    });

    const recCount = BankStatementShit.countIncludeRecords(statement);
    Object.assign(statement.average, {
      grossDepositsAmt: this.ratio(statement.total.grossDepositsAmt, recCount),
      depositsAmt: this.ratio(statement.total.depositsAmt, recCount),
      avgDailyBankBalanceAmt: this.ratio(statement.total.avgDailyBankBalanceAmt, recCount),
      monthlyWithdrawalsAmt: this.ratio(statement.total.monthlyWithdrawalsAmt, recCount),
      endMonthBankBalanceAmt: this.ratio(statement.total.endMonthBankBalanceAmt, recCount),
      monthlyBankDeposits: this.ratio(statement.total.monthlyBankDeposits, recCount),
      daysNegative: this.ratio(statement.total.daysNegative, recCount),
      NSFItems: this.ratio(statement.total.NSFItems, recCount),
      ODItems: this.ratio(statement.total.ODItems, recCount),
    } as BankStatementRec);

    this.statement.set(statement);
  }

  saveBankStmtAnalysis() {
    return forkJoin([
      this.mcaService.saveMCAComponent(this.mca().id, 'bankStatementShits', this.mca().bankStatementShits).pipe(
        tap(() => {
          this.messageService.add({
            severity: 'success',
            summary: 'Success Message',
            detail: 'Bank statement information updated',
          });
        }),
      ),

      this.mcaService.saveMCAComponent(this.mca().id, 'bankStatementTotal', this.mca().bankStatementTotal).pipe(
        tap(() => {
          this.messageService.add({
            severity: 'success',
            summary: 'Success Message',
            detail: 'Bank statement information total updated',
          });
        }),
      ),

      this.mcaService.saveMCAComponent(this.mca().id, 'bankStatementAvg', this.mca().bankStatementAvg).pipe(
        tap(() => {
          this.messageService.add({
            severity: 'success',
            summary: 'Success Message',
            detail: 'Bank statement information average updated',
          });
        }),
      ),
    ]);
  }

  save() {
    if (
      this.mca().bankStatementShits.some(item =>
        item.recs.some(rec => rec.data.grossDepositsAmt && rec.data.grossDepositsAmt < rec.data.depositsAmt),
      )
    ) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error Message',
        detail: 'Gross deposits can not be less than True deposits',
      });
      return;
    }

    this.loading.set(true);
    this.saveBankStmtAnalysis()
      .pipe(
        catchError(error => {
          this.messageService.add({ severity: 'error', detail: error.message });
          return EMPTY;
        }),
        finalize(() => this.loading.set(false)),
      )
      .subscribe();
  }

  private ratio(num: number, cnt: number) {
    return cnt && num / cnt;
  }
}
