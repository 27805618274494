import { Injectable, inject } from '@angular/core';
import { DialogService } from '@mca/shared/feature-dialog';
import { EMPTY, catchError, filter, finalize, map, switchMap, tap } from 'rxjs';
import { MongoAddress } from '@mca/shared/domain';
import { SelectItem } from 'primeng/api';
import { accountReceivableUserFields } from './account-receivable-modals.config';
import { updateDynamicFormFields } from '@mca/shared/feature-dynamic-form';
import { AccountReceivableUser, AccountReceivableUserWrite, UserService } from '@mca/user/api';

@Injectable({
  providedIn: 'root',
})
export class AccountReceivableService {
  private dialogService = inject(DialogService);
  private userService = inject(UserService);

  openViewDialog(params: { user?: AccountReceivableUser; industryOptions: SelectItem[]; withAssign: boolean }) {
    const fields = this.getFields(params);
    fields.forEach(f => {
      f.readonly = true;
      f.disabled = true;
      f.componentInputs = { ...f.componentInputs, disabled: true };
    });
    return this.dialogService.open<AccountReceivableUserWrite & { assign?: boolean }>({
      title: 'Read-Only View of the Account Receivable',
      fields,
      styleClass: 'dialog__size-xl',
      confirmLabel: '',
      cancelLabel: 'Close',
    });
  }

  openEditUserDialog(params: { user?: AccountReceivableUser; industryOptions: SelectItem[]; withAssign: boolean }) {
    return this.dialogService
      .open<AccountReceivableUserWrite & { assign?: boolean }>({
        title: params.user ? 'Edit account receivable user' : 'Create account receivable user',
        fields: this.getFields(params),
        styleClass: 'dialog__size-xl',
      })
      .pipe(
        filter(Boolean),
        switchMap(formData => {
          const userData = {
            ...formData,
            address: formData.address ?? new MongoAddress(),
            emails: formData.emails?.toString().split(',') ?? [],
          };
          delete userData.assign;
          const request$ = params.user
            ? this.userService.updateAccountReceivableUser(params.user.id, userData)
            : this.userService.createAccountReceivableUser(userData);
          return request$.pipe(
            map(({ id }) => ({ ...formData, id })),
            catchError(() => EMPTY),
            finalize(() => this.dialogService.stopLoading()),
          );
        }),
        tap(() => {
          this.dialogService.closeModal();
        }),
      );
  }

  private getFields(params: { user?: AccountReceivableUser; industryOptions: SelectItem[]; withAssign: boolean }) {
    const fields = accountReceivableUserFields.map(f => ({ ...f }));
    updateDynamicFormFields(fields, [
      {
        name: 'industry',
        options: {
          items: params.industryOptions,
          bindLabel: 'label',
          bindValue: 'value',
        },
      },
    ]);
    if (params.user) {
      const fieldValues = fields.map(({ name }) => ({
        name,
        value: params.user?.[name as keyof AccountReceivableUser],
      }));
      updateDynamicFormFields(fields, fieldValues);
      updateDynamicFormFields(fields, [
        {
          name: 'address',
          componentInputs: { address: params.user.address ?? new MongoAddress() },
          value: params.user.address,
        },
        { name: 'assign', skip: true },
        { name: 'is_active', class: 'd-flex justify-content-end' },
        { name: 'emails', value: params.user.emails ? params.user.emails.join(',') : '' },
      ]);
    }
    if (!params.withAssign) {
      updateDynamicFormFields(fields, [{ name: 'assign', skip: true }]);
    }
    return fields;
  }
}
