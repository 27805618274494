import { Component, computed, input } from '@angular/core';
import { ExposureRec } from '@mca/mca/api';
import { SafeNumberPipe } from '@mca/shared/ui';
import { DecimalPipe } from '@angular/common';
import { SharedModule } from 'primeng/api';
import { TableModule } from 'primeng/table';

@Component({
  selector: 'lib-shared-smart-ui-expected-exposure',
  templateUrl: './expected-exposure.component.html',
  standalone: true,
  imports: [TableModule, SharedModule, DecimalPipe, SafeNumberPipe],
})
export class ExpectedExposureComponent {
  exposureRec = input(new ExposureRec());
  mcaid = input(0);
  name = input('');

  title = computed(() => {
    let result = 'Exposure';
    if (this.mcaid() > 0) {
      result += ' (MCA# ' + String(this.mcaid());
    }
    if (this.name() && this.name() !== '***') {
      result += ', Name: ' + this.name();
    }
    return result + ')';
  });

  print(): void {
    const printContents = document.getElementById('printExposureTable')?.innerHTML ?? '';
    const originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
  }
}
