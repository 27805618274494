<div class="page-header mb-3">
  <div class="row">
    <div class="col-12 d-flex align-items-end">
      <h3 class="d-inline m-0">{{ title() }}</h3>
      <ol class="breadcrumb d-inline-flex ms-4 p-0 bg-transparent">
        <li class="breadcrumb-item">
          <a [routerLink]="'/'">
            <i class="fa fa-home"></i>
          </a>
        </li>
        @for (b of breadcrumbs(); track b; let last = $last) {
          <li class="breadcrumb-item" [class.active]="last">
            @if (b.link && !last) {
              <a [routerLink]="b.link">{{ b.name }}</a>
            } @else {
              {{ b.name }}
            }
          </li>
        }
      </ol>
    </div>
  </div>
</div>
