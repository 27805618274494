<div>
  <p-fieldset [toggleable]="true" legend="{{ statement().year && statement().name ? statement().year + ' - ' + statement().name : '' }}">
    <div class="row align-items-center mb-2" [formGroup]="form">
      <div class="col-sm-12 col-md-3">
        <input pInputText type="text" formControlName="name" />
      </div>
      <div class="col-sm-12 col-md-3">
        <input pInputText type="number" formControlName="year" />
      </div>
      @if (!disabled()) {
        <div class="col-sm-12 col-md-3">
          <p-multiSelect [options]="monthOptions" formControlName="monthSelected"></p-multiSelect>
        </div>
        <div class="col-sm-12 col-md-3 text-end">
          @if (!disabled()) {
            <lib-shared-ui-submit-btn class="me-2" [loading]="loading()" (btnClick)="save()">
              <i class="fa fa-save"></i> Save
            </lib-shared-ui-submit-btn>
          }
          <button class="btn btn-danger" type="button" (click)="confirmDelete()" label="Delete" [disabled]="disabled()">
            <i class="fa fa-trash"></i> Delete
          </button>
        </div>
      }
    </div>
    <p-table [value]="statement().recs" (onEditComplete)="recalc()">
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th style="width: 5%">Month</th>
          <th>Gross Dep</th>
          <th>True Dep</th>
          <th pTooltip="Avg Daily Bank Balance">Avg Daily Bal</th>
          <th>Monthly Withdr</th>
          <th pTooltip="End of Month Bank Balance">EOM Bal</th>
          <th style="width: 5%" pTooltip="# Monthly Bank Deposits"># Mon Dep</th>
          <th style="width: 5%" pTooltip="# of Days Negative">Neg#</th>
          <th style="width: 5%" pTooltip="# NSF Items">NSF#</th>
          <th style="width: 5%" pTooltip="# OD Items">OD#</th>
        </tr>
      </ng-template>
      <ng-template let-rec pTemplate="body">
        <tr>
          <td>{{ rec.month }}</td>
          <td pEditableColumn [ngStyle]="{ 'background-color': rec.processed ? '#9feab8' : '#f3f3f3' }">
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input
                  type="text"
                  mask="separator.2"
                  [ngModel]="rec.data.grossDepositsAmt || null"
                  (ngModelChange)="rec.data.grossDepositsAmt = +$event"
                  [disabled]="disabled()"
                />
              </ng-template>
              <ng-template pTemplate="output">
                <span [ngClass]="{ 'text-danger': rec.data.grossDepositsAmt && rec.data.grossDepositsAmt < rec.data.depositsAmt }">{{
                  rec.data.grossDepositsAmt | currency
                }}</span>
              </ng-template>
            </p-cellEditor>
          </td>
          <td pEditableColumn [ngStyle]="{ 'background-color': rec.processed ? '#9feab8' : '#f3f3f3' }">
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input
                  type="text"
                  mask="separator.2"
                  [ngModel]="rec.data.depositsAmt || null"
                  (ngModelChange)="rec.data.depositsAmt = +$event"
                  [disabled]="disabled()"
                />
              </ng-template>
              <ng-template pTemplate="output">
                {{ rec.data.depositsAmt | currency }}
              </ng-template>
            </p-cellEditor>
          </td>
          <td pEditableColumn [ngStyle]="{ 'background-color': rec.processed ? '#9feab8' : '#f3f3f3' }">
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input
                  type="text"
                  mask="separator.2"
                  [ngModel]="rec.data.avgDailyBankBalanceAmt || null"
                  (ngModelChange)="rec.data.avgDailyBankBalanceAmt = +$event"
                  [disabled]="disabled()"
                />
              </ng-template>
              <ng-template pTemplate="output">
                {{ rec.data.avgDailyBankBalanceAmt | currency }}
              </ng-template>
            </p-cellEditor>
          </td>
          <td pEditableColumn [ngStyle]="{ 'background-color': rec.processed ? '#9feab8' : '#f3f3f3' }">
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input
                  type="text"
                  mask="separator.2"
                  [ngModel]="rec.data.monthlyWithdrawalsAmt || null"
                  (ngModelChange)="rec.data.monthlyWithdrawalsAmt = +$event"
                  [disabled]="disabled()"
                />
              </ng-template>
              <ng-template pTemplate="output">
                {{ rec.data.monthlyWithdrawalsAmt | currency }}
              </ng-template>
            </p-cellEditor>
          </td>
          <td pEditableColumn [ngStyle]="{ 'background-color': rec.processed ? '#9feab8' : '#f3f3f3' }">
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input
                  type="text"
                  mask="separator.2"
                  [ngModel]="rec.data.endMonthBankBalanceAmt || null"
                  (ngModelChange)="rec.data.endMonthBankBalanceAmt = +$event"
                  [disabled]="disabled()"
                />
              </ng-template>
              <ng-template pTemplate="output">
                {{ rec.data.endMonthBankBalanceAmt | currency }}
              </ng-template>
            </p-cellEditor>
          </td>
          <td pEditableColumn [ngStyle]="{ 'background-color': rec.processed ? '#9feab8' : '#f3f3f3' }">
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input
                  type="text"
                  mask="separator.0"
                  [ngModel]="rec.data.monthlyBankDeposits || null"
                  (ngModelChange)="rec.data.monthlyBankDeposits = +$event"
                  [disabled]="disabled()"
                />
              </ng-template>
              <ng-template pTemplate="output">
                {{ rec.data.monthlyBankDeposits | number }}
              </ng-template>
            </p-cellEditor>
          </td>
          <td pEditableColumn [ngStyle]="{ 'background-color': rec.processed ? '#9feab8' : '#f3f3f3' }">
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input
                  type="text"
                  mask="separator.0"
                  [ngModel]="rec.data.daysNegative || null"
                  (ngModelChange)="rec.data.daysNegative = +$event"
                  [disabled]="disabled()"
                />
              </ng-template>
              <ng-template pTemplate="output">
                {{ rec.data.daysNegative | number }}
              </ng-template>
            </p-cellEditor>
          </td>
          <td pEditableColumn [ngStyle]="{ 'background-color': rec.processed ? '#9feab8' : '#f3f3f3' }">
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input
                  type="text"
                  mask="separator.0"
                  [ngModel]="rec.data.NSFItems || null"
                  (ngModelChange)="rec.data.NSFItems = +$event"
                  [disabled]="disabled()"
                />
              </ng-template>
              <ng-template pTemplate="output">
                {{ rec.data.NSFItems | number }}
              </ng-template>
            </p-cellEditor>
          </td>
          <td pEditableColumn [ngStyle]="{ 'background-color': rec.processed ? '#9feab8' : '#f3f3f3' }">
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input
                  type="text"
                  mask="separator.0"
                  [ngModel]="rec.data.ODItems || null"
                  (ngModelChange)="rec.data.ODItems = +$event"
                  [disabled]="disabled()"
                />
              </ng-template>
              <ng-template pTemplate="output">
                {{ rec.data.ODItems | number }}
              </ng-template>
            </p-cellEditor>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="footer">
        <tr>
          <td>Total</td>
          <td>{{ statement().total.grossDepositsAmt | currency }}</td>
          <td>{{ statement().total.depositsAmt | currency }}</td>
          <td>{{ statement().total.avgDailyBankBalanceAmt | currency }}</td>
          <td>{{ statement().total.monthlyWithdrawalsAmt | currency }}</td>
          <td>{{ statement().total.endMonthBankBalanceAmt | currency }}</td>
          <td>{{ statement().total.monthlyBankDeposits | number: '1.0-2' }}</td>
          <td>{{ statement().total.daysNegative | number: '1.0-2' }}</td>
          <td>{{ statement().total.NSFItems | number: '1.0-2' }}</td>
          <td>{{ statement().total.ODItems | number: '1.0-2' }}</td>
        </tr>
        <tr>
          <td>Average</td>
          <td>{{ statement().average.grossDepositsAmt | currency }}</td>
          <td>{{ statement().average.depositsAmt | currency }}</td>
          <td>{{ statement().average.avgDailyBankBalanceAmt | currency }}</td>
          <td>{{ statement().average.monthlyWithdrawalsAmt | currency }}</td>
          <td>{{ statement().average.endMonthBankBalanceAmt | currency }}</td>
          <td>{{ statement().average.monthlyBankDeposits | number: '1.0-2' }}</td>
          <td>{{ statement().average.daysNegative | number: '1.0-2' }}</td>
          <td>{{ statement().average.NSFItems | number: '1.0-2' }}</td>
          <td>{{ statement().average.ODItems | number: '1.0-2' }}</td>
        </tr>
      </ng-template>
    </p-table>
  </p-fieldset>
</div>
